import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LoadingStateService {
  private readonly _loading = new BehaviorSubject<boolean>(false);

  get loading$(): Observable<boolean> {
    return this._loading.asObservable();
  }

  stopLoading(): void {
    this._loading.next(false);
  }

  startLoading(): void {
    this._loading.next(true);
  }
}
