// @ts-nocheck
import {Injectable, makeStateKey, TransferState} from '@angular/core';

import {ApolloClientOptions, InMemoryCache} from '@apollo/client/core';
import {Storage} from '@ionic/storage-angular';
import {HttpLink} from 'apollo-angular/http';
import {persistCache} from 'apollo3-cache-persist';
import {environment} from '../../environments/environment';

export function resolveApolloClientOptions(apolloClientService: ApolloConfigService): unknown {
  return async () => apolloClientService.getOptionsPromise();
}

export function apolloClientServiceFactory(apolloClientService: ApolloConfigService): unknown {
  return apolloClientService.getOptions();
}

export function nullable(): unknown {
  return {
    read(existing = null): unknown {
      return existing;
    },
  };
}

export function mergePaginate(): unknown {
  return {
    merge(existing, incoming, {readField}): unknown {
      const merged = {...existing};
      incoming.forEach((item) => {
        merged[readField('id', item)] = item;
      });
      return merged;
    },
    read(existing): unknown {
      return existing && Object.values(existing);
    },
  };
}

export const STATE_KEY = makeStateKey('apollo.state');

@Injectable({providedIn: 'root'})
export class ApolloConfigService {
  readonly cache;

  async clearApolloCachePersisted(): Promise<void> {
    return this.ionicStorage.clear();
  }

  constructor(
    private readonly httpLink: HttpLink,
    private readonly ionicStorage: Storage,
    private readonly transferState: TransferState,
  ) {
    this.cache = new InMemoryCache({
      typePolicies: {
        Paillarde: {
          merge: true,
          keyFields: ['slug'],
        },
        PaillardeEntity: {
          merge: true,
        },
        PaillardeEntityResponseCollection: {
          fields: {
            data: mergePaginate(),
          },
        },
        PaillardeRelationResponseCollection: {
          fields: {
            data: mergePaginate(),
          },
        },
        Book: {
          merge: true,
          keyFields: ['slug'],
        },
        BookEntity: {
          merge: true,
        },
        BookEntityResponseCollection: {
          fields: {
            data: mergePaginate(),
          },
        },
        Album: {
          merge: true,
          keyFields: ['slug'],
        },
        AlbumEntity: {
          merge: true,
        },
        AlbumEntityResponseCollection: {
          fields: {
            data: mergePaginate(),
          },
        },
        PaillardesCategory: {
          merge: true,
          keyFields: ['slug'],
          fields: {
            paillardes: {
              keyArgs: [],
            },
          },
        },
        PaillardesCategoryEntity: {
          merge: true,
        },
        PaillardesCategoryEntityResponseCollection: {
          fields: {
            data: mergePaginate(),
          },
        },
        UploadFile: {
          merge: true,
        },
        UploadFileEntity: {
          merge: true,
        },
        UploadFileEntityResponseCollection: {
          fields: {
            data: mergePaginate(),
          },
        },
        Query: {
          fields: {
            paillardes: {
              keyArgs: ['filters'],
            },
            paillardesCategories: {
              keyArgs: ['filters'],
            },
            books: {
              keyArgs: ['filters'],
            },
            albums: {
              keyArgs: ['filters'],
            },
          },
        },
      },
    });
  }

  getOptions(): ApolloClientOptions<unknown> {
    const isBrowser = this.transferState.hasKey(STATE_KEY);
    if (isBrowser) {
      const state = this.transferState.get<unknown>(STATE_KEY, null);
      this.cache.restore(state);
    }
    return {
      link: this.httpLink.create({
        uri: environment.apiGraphqlEndpoint,
      }),
      cache: this.cache,
      connectToDevTools: environment.apolloDevtools,
    };
  }

  async getOptionsPromise(): Promise<void> {
    return this.ionicStorage.create().then(
      async (): Promise<void> =>
        persistCache({
          cache: this.cache,
          storage: {
            getItem: async (key: string) => this.ionicStorage.get(key),
            setItem: async (key: string, data: unknown) => this.ionicStorage.set(key, data),
            removeItem: async (key: string) => this.ionicStorage.remove(key),
          },
          maxSize: false,
          key: 'paillardes-cache-persist',
          debug: !environment.production,
          serialize: false,
        }),
    );
  }
}
